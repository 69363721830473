import React, { useState } from "react";
import { Link, useLocation,useNavigate} from "react-router-dom";

function SurveyCreate() {
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const handleSubmit = async event => {
     event.preventDefault();
     setLoading(true);
     const data = {
       'name': name,
       'description': description,
       'id_organization': 1
     }
     try {
       const response = await fetch(`${process.env.REACT_APP_LIME_API_URL}/survey`, {
         method: 'POST',
         headers: {
           Accept: 'application/form-data',
           'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
       });

       const result = await response.json();
       console.log(result)
       if (result['data'] =='Survey Saved!') {
         alert('Survey created successfully');
         setTimeout(() => {
          navigate('/admin');
        }, 0);
        
       } 
     } catch (error) {
       console.error('Error:', error);
       alert('Error creating survey');
     } finally {
       setLoading(false);
     }
   }

   return (
       <form className="w-full max-w-lg mx-auto my-20 " onSubmit={handleSubmit}>
       <div className="flex flex-wrap mx-3 mb-2">
           <div className="w-full px-3">
           <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-first-name">
               Name
           </label>
           <input
           className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white" id="grid-first-name"
           type="text"
           placeholder="Name"
           onChange={(e) => setName(e.target.value)}
           label="Name"
           />


           </div>
       </div>
       <div className="flex flex-wrap mx-3 mb-1">
           <div className="w-full px-3">
           <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-desc">
               Description
           </label>
           <textarea className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" id="grid-desc" type="text"
           placeholder="Survey Description"
           label="Description"
           onChange={(e) => setDescription(e.target.value)}
           />
           </div>
       </div>
       <Link to="/">
       <button type="button" className="inline-flex items-center px-4 py-2 ml-8 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => navigate(-1)}>
           BACK
       </button>
       </Link>
       <button type="submit" className="inline-flex items-center px-4 py-2 ml-8 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" disabled={loading}>
           {loading ? "Loading..." : "SUBMIT"}
       </button>
       </form>
   )
}

export default SurveyCreate;