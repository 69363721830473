import React from 'react';
import { Toast } from 'react-bootstrap';

export default function ErrorToast({ message }) {
    return (
        <Toast bg='danger' className='d-inline-block m-1'>
            <Toast.Body>
                <strong className='me-auto'>{message}</strong>
            </Toast.Body>
        </Toast>
    );
}
