import '../layouts/App.css';
import '../layouts/lime.css';
import React from 'react';
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import "survey-core/modern.min.css";
import "survey-core/survey.i18n.min.js";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner'
import Footer from '../views/Footer.jsx';
import ErrorToast from '../components/ErrorToast.jsx'
import useSurvey from '../components/Surveys/useSurvey.js';
import { Helmet } from "react-helmet";
import useModal from '../components/Surveys/useModal.js';
import { useState, useEffect } from "react";

export default function SurveyView({ id_survey: idSurveyProp }) {
    const navigate = useNavigate();
    const { id_survey: idSurveyParam } = useParams();
    const id_survey = idSurveyProp || idSurveyParam;
    const { json, loading, error,isModalOpen, handleValueChanged, handlePageChanged, handleComplete,setIsModalOpen } = useSurvey(id_survey, navigate);
    const [modalText, setModalText] = useState("Obrigado por participar.Aguarde enquanto o formulário está salvando...");
    // Apply the "modern" theme
    Survey.StylesManager.applyTheme("modern");
    // Create a new Survey.Model from the json data
    const surveyModel = new Survey.Model(json);
    const [Modal, openModal, closeModal,showLoading,hideLoading] = useModal();

    // Attach the event handlers to the survey model
    surveyModel.onValueChanged.add(handleValueChanged);
    surveyModel.onCurrentPageChanged.add(handlePageChanged);

    surveyModel.onComplete.add(handleComplete);
    // surveyModel.onCompleting.add((survey, options) => { 
    //     //sleep 5 sec
    //     setTimeout(() => {
    //         setIsModalOpen(true);
    //     }, 5000);

    // });
    useEffect(() => {
        if (isModalOpen) {
            if(error){
                setModalText("Erro ao salvar o formulário. Detalhes: "+ error);
                hideLoading();
            }
            openModal();
        } else {
            closeModal();   
        }
    }, [isModalOpen,error]);


    // surveyModel.onValidateQuestion.add((survey, options) => {
    //     if (options.name === "token") {
    //         console.log("token: " + options.value);
    //         fetch(`https://j5w5ag7110.execute-api.us-east-1.amazonaws.com/verginia-check-token?token=${options.value}`, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             mode: "cors"
    //         })
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log(data);
    //             if (data.status.trim() === "token_encontrado") {
    //                 console.log("token encontrado: " + options.value);
    //             } else {
    //                 options.error = 'Token inválido, por favor, tente novamente!';
    //             }
    //         })
    //         .catch(error => {
    //             console.log('Error:', error);
    //         });


    //     }
    //   });

    return (
        <div>
            <Helmet>
                <link rel="stylesheet"
                    href={`${process.env.REACT_APP_LIME_API_URL}/survey/css/${id_survey}`} />
            </Helmet>
            <div className={loading ? "hidden" : "flex flex-col justify-between h-full w-full bg-survey"} >
                <div className="h-full py-4 mb-auto bg-survey">
                    {!loading && <SurveyReact.Survey model={surveyModel} />}
                </div>
                <Footer />
            </div>
            <div className={"" + (!loading ? " hidden " : "centralizar-vertical-horizontal mb-3 mt-20")}>
                <ThreeCircles color="#F5D728" height={80} width={80} />
            </div>
            {error && <ErrorToast message={error} />}
            <Modal 
                title="Aguarde..." 
                content={`${modalText}`}
                isLoading={true}
            />

        </div>
    );
}