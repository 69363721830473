import React, { useState, useRef, useEffect } from 'react';
import './Chatbot.css';
import ReactMarkdown from 'react-markdown'
const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(scrollToBottom, [messages]);

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const getBotResponse = async (message) => {
        const response = await fetch('http://localhost:5000/chatapi', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "input":message }),
            credentials: 'include'
        });
        console.log(response)
        if (response.ok) {
            const jsonResponse = await response.text();
            return jsonResponse;
        }

        return 'Error: Could not fetch a response from the API.';
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (inputMessage.trim()) {
            setMessages([...messages, { text: inputMessage.trim(), user: 'You' }]);
            setInputMessage('');

            const botResponse = await getBotResponse(inputMessage.trim());
            setMessages((prevMessages) => [...prevMessages, { text: botResponse, user: 'Chatbot' }]);
        }
    };

    return (


<div className="conversationStyle h-full bg-gray-800 text-white flex flex-col">
  <div className='phantomConversationStyle'></div>
  <div className="flex-grow flex flex-col-reverse" >
    <div className="p-4 space-y-2">
      {messages.map((message, index) => (
        <div key={index} className="flex items-start mb-2">
          <strong className="mr-2">{message.user}: </strong>

          <span> <ReactMarkdown children={message.text}></ReactMarkdown></span>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  </div>
  <form className="w-full flex p-2 bg-gray-700 border-t border-gray-600" onSubmit={sendMessage}>
    <input
      type="text"
      placeholder="Type your message here..."
      value={inputMessage}
      onChange={handleInputChange}
      className="flex-grow border border-gray-600 rounded px-2 py-1 bg-gray-700 text-white outline-none"
    />
    <button
      type="submit"
      className="bg-blue-500 text-white px-4 py-1 rounded ml-2 hover:bg-blue-600"
    >
      Send
    </button>
  </form>
</div>



    );
};

export default Chatbot;
