export const API_SAVE_URL = 'https://j5w5ag7110.execute-api.us-east-1.amazonaws.com/lime-pi-save-answer';
export const API_EMAIL_URL = 'https://j5w5ag7110.execute-api.us-east-1.amazonaws.com/ch-source-skills-email-sender';

export const saveData = async (guid, data, detailed) => {
    if (typeof data !== 'object' || Object.keys(data).length === 0) {
        throw new Error("Invalid data");
    }
    //throw new Error("Erro ao salvaaar");
    const response = await fetch(API_SAVE_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error("Error occurred during data saving: " + response.status);
    }
    console.log(response)
    console.log("Data saved successfully")

    return response;
}
export const retry = async (fn, retries, delay) => {
    try {
        return await fn();
    } catch (err) {
        if (retries > 0) {
            console.log(err);
            console.log(`Retrying in ${delay}ms... (${retries - 1} retries left)`);
            await new Promise(res => setTimeout(res, delay));
            return retry(fn, retries - 1, delay);
        } else {
            throw err;
        }
    }
}

export const sendEmail = async (guid, data) => {
    const response = await fetch(API_EMAIL_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'guid': guid,
            'email': data.info_email
        },
        crossDomain: true,
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error('Error in sending email: ' + response.status);
    }

    return response.json();
}
