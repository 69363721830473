import React, { useState, useEffect, useRef } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import FetchAiDimensionRecommendation from '../../api/FetchAiDimensionRecommendation.tsx';

function DimensoesRecomendacoesLoader({context_filter}) {
  const [loading_recomendacoes, setLoadingRecomendacoes] = useState(true);
  const [recomendacoes_html, setRecomendacoesHtml] = useState(null);
  const fetchRetryCount = useRef(0);

  useEffect(() => {
    let retryFetchInterval;

    async function fetchData() {
      const recomendacoes_html = await FetchAiDimensionRecommendation(context_filter);

      if (recomendacoes_html && typeof recomendacoes_html === 'string' && recomendacoes_html.trim().length > 0) {
        setRecomendacoesHtml(recomendacoes_html);
        setLoadingRecomendacoes(false);
        fetchRetryCount.current = 0;
      } else if (fetchRetryCount.current < 24) {
        const seconds = 15 ;
        fetchRetryCount.current += 1;
        retryFetchInterval = setTimeout(fetchData, seconds*1000); 
      }
    }

    fetchData();

    // Clean up function to clear retry interval on unmount
    return () => {
      if (retryFetchInterval) {
        clearTimeout(retryFetchInterval);
      }
    };

  }, [context_filter]);

  return (
    <div>
      {
        !loading_recomendacoes ? <div dangerouslySetInnerHTML={{__html: recomendacoes_html }} ></div>: 
        <div style={{ display: "flex", alignItems: "center" }} >
          <ThreeCircles color="#F5D728" height={80} width={80} />
          <span style={{ marginLeft: "10px" }}>{"Carregando recomendações por IA. Isso pode levar alguns minutos... Aguarde..."}</span>
        </div>
      }
    </div>
  );
}

export default DimensoesRecomendacoesLoader;
