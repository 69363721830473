import { FetchDataBlock } from './DataBlock';

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    filters: { dimension_type: string[] }[];
    metrics: { metric: string, agg_function: string, distinct?: boolean }[];
}

interface ResponseData {
    count_guid_answer: []
}

export default async function FetchCountAnswer(token: string): Promise<ResponseData | null> {

    const requestData: RequestData = {
        "context": 'token',
        'token': token,
        "return_type": "column",
        "metrics": [{
            "metric": "guid_answer",
            "agg_function": "COUNT",
            "distinct": true
        }
        ],
        "filters": [{
            "dimension_type": ['Survey Metadadata Field']
        }],
    };

    try {
        const jsonData: ResponseData = await FetchDataBlock(requestData);
        console.log(jsonData);
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}
