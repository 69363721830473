import React, { useState, useEffect } from 'react';

const RandomAiBanner = () => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadRandomImage = () => {
      const imagesFolder = '../midia/lime_pi_banners_ai'; // Replace with your actual folder path
      const images = 10; // Replace with your actual images

      const randomIndex = Math.floor(Math.random() * images);
      const randomImage = 'lime_pi_banner_ai_' + randomIndex + '.png';

      setImageSrc(`${imagesFolder}/${randomImage}`);
    };

    loadRandomImage();
  }, []);

  return (
    <div
      style={{
        height: '35vh',
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default RandomAiBanner;
