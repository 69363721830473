import React, { useState,useEffect} from "react";
import { Link,useParams } from "react-router-dom";
import { ThreeCircles } from  'react-loader-spinner'


function SurveyEdit() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [css, setCss] = useState('');
  const params = useParams();

  const ReadSurvey = async () => {

    fetch(`${process.env.REACT_APP_LIME_API_URL}/survey/${params.id_survey}`)
      .then(res  => {
         // console.log('res');
         // console.log(res.json());
         return res.json();
     })
      .then(result => {
        console.log(result.name)
         setName(result.name);
         setDescription(result.description);
         setCss(result.css);
         setLoading(false);
        }
      )
}


  useEffect(() => {
    ReadSurvey()
   }, [])




   const handleSubmit = event => {
     event.preventDefault();
     var data = {
       'name': name,
       'description': description,
       'css':css,
       'id_survey': params.id_survey

     }
     console.log(data);
     fetch(`${process.env.REACT_APP_LIME_API_URL}/survey`, {
       method: 'POST',
       headers: {
         Accept: 'application/form-data',
         'Content-Type': 'text/html',
       },
       body: JSON.stringify(data),
     })
     .then(res => {
      return res;
    })
     .then(
       async (result) => {
          const ret = await result.json();
          console.log(ret['data']);
          alert(ret['data']);
          if (result['status'] ===200) {
           window.location.href = '/';
          }
       }
     )
   }


   return (
      <div>
       <form className={loading?"hidden":"w-full h-full max-w-lg mx-auto my-20"} onSubmit={handleSubmit}>
       <div className="flex flex-wrap mx-3 mb-2">
           <div className="w-full px-3">
           <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-first-name">
               Name
           </label>
           <input
           className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white" id="grid-first-name"
           type="text"
           placeholder="Name"
           onChange={(e) => setName(e.target.value)}
           value={name}
           label="Name"
           />


           </div>
       </div>
       <div className="flex flex-wrap mx-3 mb-1">
           <div className="w-full px-3">
           <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-desc">
               Description
           </label>
           <textarea className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" id="grid-desc" type="text"
           placeholder="Survey Description"
           label="Description"
           onChange={(e) => setDescription(e.target.value)}
           value ={description}
           />
           </div>
       </div>
       <div className="flex flex-wrap mx-3 mb-1">
           <div className="w-full px-3">
           <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-desc">
               Custom CSS
           </label>
           <textarea className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" id="grid-desc" type="text"
           placeholder="Survey Custom CSS"
           label="Custom CSS"
           onChange={(e) =>setCss(e.target.value)}
           defaultValue={{/* Hello, World! */}}
           value ={css}
           outputFormats="preserved"
           />
           </div>
       </div>
       <Link to="/">
       <button type="button" className="inline-flex items-center px-4 py-2 ml-8 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
           BACK
       </button>
       </Link>
       <button type="reset" className="inline-flex items-center px-4 py-2 ml-8 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
           RESET
       </button>
       <button type="submit" className="inline-flex items-center px-4 py-2 ml-8 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
           SUBMIT
       </button>
       </form>
       <div className={""+ (!loading ? " hidden ":"centralizar-vertical-horizontal mb-3")}>
              <ThreeCircles color="#F5D728" height={80} width={80} />
        </div>
       </div>
   )
}

export default SurveyEdit;