import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { ThreeCircles } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

function SurveyList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [survey, setSurvey] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);

    const ReadSurveys = async () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_LIME_API_URL}/survey`)
            .then(res => res.json())
            .then(result => {
                setData(result);
                setLoading(false);
            })
            .catch(error => setError('An error occurred: ' + error.message));
    }

    const DeleteSurvey = async (id) => {
        var data = {
            'id': id
        }

        try {
            let res = await fetch(`${process.env.REACT_APP_LIME_API_URL}/survey/${id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })

            if (!res.ok) {
                throw new Error('HTTP status ' + res.status);
            }

            let result = await res.json();

            if (result !== undefined) {
                alert(`Survey ${id} deleted successfully`);
                setLoading(true);
                setForceUpdate(prev => !prev); // toggle the forceUpdate state
                await ReadSurveys();
            }

        } catch (error) {
            setError('An error occurred: ' + error.message);
        }
    }

    const CopySurvey = id => {
        var data = {
            'id': id
        }
        fetch(`${process.env.REACT_APP_LIME_API_URL}/survey/copy/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(
                (result) => {

                    if (result !== undefined) {

                        alert(`Survey ${id} copied successfully`);
                        ReadSurveys()
                    }
                }
            )
    }
    const columns = React.useMemo(
        () => [
            {
                id: 'id_survey',
                Header: 'ID',
                accessor: 'id_survey',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value }) => (
                    <div style={{
                        overflow: 'auto',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}>
                        {value}
                    </div>
                ),
            },
            {
                id: 'actions',
                Header: 'Actions',
                accessor: 'id_survey',
                Cell: ({ value }) => (
                    <span className="flex space-x-2">
                        <Link to={`/Survey/View/${value}`}>
                            <button className="px-4 py-2 mt-4 text-sm leading-none text-green-800 border border-green-600 rounded hover:bg-green-300 hover:text-green-500 lg:mt-0">View</button>
                        </Link>
                        <Link to={`/Admin/Survey/Edit/${value}`}>
                            <button className="px-4 py-2 mt-4 text-sm leading-none text-blue-800 border border-blue-600 rounded hover:bg-blue-300 hover:text-blue-500 lg:mt-0">EDIT</button>
                        </Link>
                        <Link to={`/Survey/Editor/${value}`}>
                            <button className="px-4 py-2 mt-4 text-sm leading-none text-blue-800 border border-blue-600 rounded hover:bg-blue-300 hover:text-blue-500 lg:mt-0">EDITOR</button>
                        </Link>
                        <button onClick={() => CopySurvey(value)} className="px-4 py-2 mt-4 text-sm leading-none text-yellow-800 border border-yellow-600 rounded hover:bg-yellow-300 hover:text-yellow-500 lg:mt-0">COPY</button>
                        <button onClick={() => DeleteSurvey(value)} className="px-4 py-2 mt-4 text-sm leading-none text-red-800 border border-red-600 rounded hover:bg-red-300 hover:text-red-500 lg:mt-0">DELETE</button>
                    </span>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        ReadSurveys();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (loading) {
        return <div className="centralizar-vertical-horizontal mb-3">
            <ThreeCircles color="#F5D728" height={80} width={80} />
        </div>;
    } else {
        return (
            <div className="flex flex-col h-full">
                <div className="-my-2 overflow-x-auto">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="px-20 py-2 lg:flex lg:items-center lg:justify-between">
                            <div className="flex-1 min-w-0">
                                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                    Surveys
                                </h2>
                            </div>
                            <div className="flex mt-5 lg:mt-0 lg:ml-4">
                                <span className="hidden sm:block">
                                    <Link to="/Survey/Create">
                                        <button type="button" className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <svg className="w-5 h-5 mr-2 -ml-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8-379-8.379-2.828 2.828.793.793a2 2 0 01-2.828-2.828l.793-.793zM11.379 5.793L3 14.172V17h2.828l8.379-8.379-2.828-2.828z" />
                                            </svg>
                                            New
                                        </button>
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="mt-2 mb-2 -mx-6 overflow-x-auto">
                                <input
                                    value={state.globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value || undefined)}
                                    placeholder="Search..."
                                    className="border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block pl-7 pr-12 sm:text-sm border-gray-300"
                                />
                                <div className="align-middle inline-block min-w-full mt-2">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                {headerGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map(column => (
                                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                {column.render('Header')}
                                                                <span>
                                                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                                                {rows.map(row => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map(cell => {
                                                                return <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">{cell.render('Cell')}</td>
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurveyList;

