import { FetchDataBlock } from './DataBlock';


interface RequestDataNew {
    context: string;
    token: string;
    return_type: string;
    dimensions: string[];
    filters: {dimension_type: string[]}[];
    metrics: {metric: string, agg_function: string, name?: string}[];
    pivots: {column: string, pivot_values: string[], output_value: string}[];
}

interface ResponseDateAndAnswer{
    end_date:Date[],
    index:Number[],
    guid_answer:string[]
    ai_demographic_summary:string[]
}

export default async function FetchGroupTimeline(token:string): Promise<ResponseDateAndAnswer | null> {
    var requestData: RequestDataNew = {
        context: "token",
        token: token,
        metrics:[{
            metric:"substitution_value",
                     agg_function:"AVG",
                     name: "Média da Competencia Colaborativa"
                     }
                 ],
        return_type: "row",
        dimensions: ["iso_end_date", "guid_answer"],
        filters: [{dimension_type: ["Competências Colaborativas","Demographic"]}],
        pivots: [
            {
                "column": "question_name",
                "pivot_values": [
                    "ai_demographic_summary"
                ],
                "output_value": "original_value"
            }
        ]
    };
    console.log(requestData)

    try {
        const jsonDataArray: Array<{iso_end_date: string, "Média da Competencia Colaborativa": number,guid_answer:string,ai_demographic_summary:string}> = await FetchDataBlock(requestData);

        
        const response_date = jsonDataArray.map(data => {
            let utcDate = new Date(data.iso_end_date);
            let offset = 3 * 60 * 60 * 1000;
            let utcMinus3Date = new Date(utcDate.getTime() - offset);
        
            return utcMinus3Date;
        });
      
        
        const index  = jsonDataArray.map(data => data["Média da Competencia Colaborativa"]);
        const guid_answer = jsonDataArray.map(data => data.guid_answer);
        const ai_demographic_summary = jsonDataArray.map(data => data.ai_demographic_summary === null ? "N/D" : data.ai_demographic_summary);

        //console.log(response_date,index);
        return {end_date: response_date, index: index,guid_answer:guid_answer,ai_demographic_summary:ai_demographic_summary};
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}