import React from "react";

// components

// import CardStats from "components/Cards/CardStats.js";
const footerStyle = {

  padding: "0px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%",
};

const phantomStyle = {
  display: "block",
  padding: "0px",
  height: "20px",
  width: "100%",

};

export default function Footer() {
  return (
    <>
        <footer style={footerStyle} className="block footer-demo lime-bg-dark text-lime-50">
            <div style={phantomStyle}> 2023 <i className="fa fa-heart fa-lemon heart"></i> by Collabhapiness
            </div>
        </footer>
    </>
  );
}
