
import React from 'react';
import Chart from 'react-apexcharts';

const IndiceGeralGauge = ({ indice_geral }) => {

    var options = {
        series: [indice_geral*10],//[76],
       //series: [76],
       chart: {
           type: 'radialBar',
           offsetY: -20,
           sparkline: {
               enabled: true
           }
       },
       value: {
        offsetY: -2,
        fontSize: '22px',
        formatter: function (value) {
            if (typeof value === 'number') {
                return parseFloat(value / 10).toFixed(2)
            } else {
                // Handle non-numeric value case here
                console.error('Non-numeric value received:', value);
                return value;
            }
        },
    },
       plotOptions: {
           radialBar: {
               startAngle: -90,
               endAngle: 90,
               track: {
                   background: "#e7e7e7",
                   strokeWidth: '97%',
                   margin: 5, // margin is in pixels
                   dropShadow: {
                       enabled: true,
                       top: 2,
                       left: 0,
                       color: '#999',
                       opacity: 1,
                       blur: 2
                   }
               },
               dataLabels: {
                   name: {
                       show: false
                   },
                   value: {
                       offsetY: -2,
                       fontSize: '22px',
                       //show: false
                       formatter: function (value) {
                           return parseFloat(value / 10).toFixed(2)//(parseFloat(value) * max) / 100;
                       },
                   }
               }
           }
       },
       grid: {
           padding: {
               top: -10
           }
       },
       fill: {
           opacity: 1.5,
           colors: ['#A38129'],
           type: 'gradient',
           gradient: {
               gradientToColors: ['#FFC100'],
               shadeIntensity: 1,
               opacityFrom: 1,
               opacityTo: 2,
               stops: [0, 50, 100],
               inverseColors: false
           },
       },

       labels: ['Average Results'],
       dataLabels: {
           show: true,
           name: {
               show: true,
               fontSize: '16px',
               fontFamily: undefined,
               fontWeight: 600,
               color: undefined,
               offsetY: -10
           },
           value: {
               show: true,
               fontSize: '14px',
               fontFamily: undefined,
               fontWeight: 400,
               color: undefined,
               offsetY: 16,
               formatter: function (val) {
                   return val + '%'
               }
           },
           total: {
               show: false,
               label: 'Total',
               color: '#373d3f',
               fontSize: '16px',
               fontFamily: undefined,
               fontWeight: 600,
               formatter: function (w) {
                   return w.globals.seriesTotals.reduce((a, b) => {
                       return a + b
                   }, 0) / w.globals.series.length + '%'
               }
           }
       }
   };

    return <Chart options={options} series={options.series} type="radialBar" />;
};

export default IndiceGeralGauge;

