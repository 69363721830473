import { FetchDataBlock } from './DataBlock';

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    dimensions: string[];
    metrics: {metric: string, agg_function: string}[];
    filters: {dimension_type: string[]}[];
}

interface ResponseData {
        value:[],
        avg_substitution_value:[]
}

export default async function FetchAVGDimensionType(context:string, context_filter_value: string,dimension_type:string): Promise<ResponseData | null> {


    var requestData: RequestData = {

        "context":context,
        "return_type":"column",

        "dimensions":["dimension_value"],

        "metrics":[{
            "metric":"substitution_value",
            "agg_function":"AVG",
            }
        ],
        "filters":[{
             "dimension_type":[dimension_type]
        }],
    };
    if(context == "token")
        requestData.token = context_filter_value;
    if(context == "answer")
        requestData.guid_answer = context_filter_value;

    try {
        const jsonData: ResponseData = await FetchDataBlock(requestData);
        //console.log(jsonData);
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}
