import React from 'react';
import './Modal.css';
import { ThreeCircles } from 'react-loader-spinner';

function Modal({ isOpen, onClose, title, content, isLoading }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {title && <h2>{title}</h2>}
                {isLoading ? (
                    <div className="modal-loading">
                        <ThreeCircles color="#F5D728" height={80} width={80} />
                    </div>
                )
                    : (
                        <div>
                            
                        </div>
                    ) 
                }
                {<div className="modal-text"></div> && <p>{content}</p>}
                {/* <button onClick={onClose}>Close</button> */}
            </div>
        </div>
    );
}

export default Modal;
