import React, { useState } from 'react';
import Modal from './Modal';

export default function useModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function showLoading() {
        setIsLoading(true);
    }
    function hideLoading() {
        setIsLoading(false);
    }
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const RenderModal = ({ title, content, isLoading }) => {
        return <Modal isOpen={isOpen} onClose={closeModal} title={title} content={content} isLoading={isLoading} />
    }

    return [RenderModal, openModal, closeModal, showLoading, hideLoading];
}
