import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 

const firebaseConfig = {
  apiKey: "AIzaSyBy4UGMv5-D3asDnCXjTsMwU7L75_AuQVI",
  authDomain: "lime-pi.firebaseapp.com",
  projectId: "lime-pi",
  storageBucket: "lime-pi.appspot.com",
  messagingSenderId: "1070141744924",
  appId: "1:1070141744924:web:4ff076f9119799bd83bcd9",
  measurementId: "G-G2GZFZHGRM"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
