import React from 'react';
import Chart from 'react-apexcharts';

import { useState } from "react";
const DimensaoChart = ({ dimensao_valores, dimensao_colunas }) => {

    const [loading, setLoading] = useState(true);

    if (dimensao_valores && dimensao_colunas && dimensao_valores.length > 0 && dimensao_colunas.length > 0 && loading) {
        setLoading(false);
    }

    
    const formatLabels = (labels) => {
      return labels.map(label => {
        const words = label.split(' ');
        const lines = [];
        for (let i = 0; i < words.length; i += 2) {
          lines.push(words.slice(i, i + 2).join(' '));
        }
        return lines;
      });
    };
    
    const formattedLabels = formatLabels(dimensao_colunas);

    var options = {

        series: [{
            name: 'Series 1',
            //data: [8.0,    7.4,    2.375,    2.8  ],
            data: dimensao_valores
            //[65, 59, 90, 81]
        }],
        chart: {

            height: 'auto',
            // type: 'radar',
            type: 'bar',
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        
        dataLabels: {
            enabled: true,
            formatter: (value) => {
                return value.toFixed(2);  // two decimal places in tooltip
            },
        },
        plotOptions: {
            bar: {
                horizontal: true
                
            }
        },
        colors: ['#A38129'],
        markers: {
            size: 4,
            colors: ['#fff'],
            strokeColor: '#A38129',
            strokeWidth: 2,
            formatter: (value) => {
                return value.toFixed(2);  // two decimal places in tooltip
            },
        },
        tooltip: {
            y: {
                formatter: (value) => {
                    return value.toFixed(2);  // two decimal places in tooltip
                }
            }
        },
        xaxis: {
            categories: formattedLabels
        }
    };

    return <Chart options={options} series={options.series} type="bar" />;
};

export default DimensaoChart;