import '../layouts/App.css';
import '../layouts/lime.css';
import React from 'react';
import "survey-core/modern.min.css";
import "survey-core/survey.i18n.min.js";
import Footer from '../views/Footer.jsx';
import { Helmet } from "react-helmet";
    import { useParams } from 'react-router-dom';
export default function ThankYouPage({ id_survey: idSurveyProp }) {
    const { id_survey: idSurveyParam } = useParams();
    const id_survey = idSurveyProp || idSurveyParam;
    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href={`${process.env.REACT_APP_LIME_API_URL}/survey/css/${id_survey}`} />
            </Helmet>
            <div className="flex justify-between h-full w-full bg-survey">
                <div className="h-full py-4 m-auto bg-survey">
                    <div className="sv-root-modern">
                        <form>
                            <div className="sv_custom_header"></div>
                            <div className="sv-container-modern">
                                <div className="sv-components-column sv-components-column--expandable sv-body sv-thankyou sv-completedpage">
                                <p className="text-obrigado">
                                        Seu formulário foi recebido com sucesso!
                                    </p>
                                    <p className="text-obrigado">
                                        Obrigado por ter contribuído para melhorar o seu ambiente de trabalho e tornar a sua empresa ainda melhor!
                                    </p>
                                </div>
                            </div>
                        </form>
      
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}
