import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from './firebaseConfig';
import { AuthContext } from './AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    setError('');

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate('/Admin');
    } catch (err) {
      console.log('Error logging in: ', err)
      setError('Invalid email or password');
    }
  };

  if (user) {
    navigate('/Admin');
  }

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Login;
