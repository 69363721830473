import '../layouts/App.css';
import '../layouts/lime.css';
import React from 'react';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import SurveyList from "../survey/SurveyList.jsx";

import SurveyCreate from '../survey/SurveyCreate.jsx';
import SurveyEdit from '../survey/SurveyEdit.jsx';
// import Modal from './components/Modal.jsx';
import { Routes, Route, Outlet } from 'react-router-dom';
function SurveyAdm() {



  return (
    <div className="App"  >
      <div className="flex flex-col justify-between h-screen p-0 lime-bg-light">
        <Header />

        <div className="py-4 lime-bg-light mb-auto">
          <div className="container">
            <Routes>
              <Route path="/" element={<SurveyList />} />
              <Route path="/Survey/Create" element={<SurveyCreate />} />
              <Route path="/Survey/Edit/:id_survey" element={<SurveyEdit />} />
            </Routes>
            <Outlet />

          </div>


        </div>


        <Footer />

      </div>

    </div>

  );
}

export default SurveyAdm;
