import { FetchDataBlock } from './DataBlock';

interface Pivot {
    column: string;
    pivot_values: string[];
    output_value: string;
}

interface Filter {
    question_name: string[];
}

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    pivots: Pivot[];
    filters: Filter[];
}

interface ResponseData {
    [key: string]: any[];
}

export default async function FetchAiFatoresRecommendation(guid_answer: string): Promise<{[key: string]: any} | null> {
    var requestData: RequestData = 
    {
            "context": "answer",
            "guid_answer": guid_answer,
            "return_type": "column",
            "pivots":[{"column":"question_name",
                 "pivot_values":["ai_fatores_criacao_coletiva_recomendacao_1",
                    "ai_fatores_criacao_coletiva_recomendacao_2",
                    "ai_fatores_autoconsciencia_prol_coletivo_1",
                    "ai_fatores_alinhamento_para_resultados",
                    "ai_compartilhamento_de_conhecimento",
                    "ai_fatores_autoconsciencia_prol_coletivo_2"],
                 "output_value":"original_value"
                 }
         ],
            "filters": [{
                "question_name": [
                    "ai_fatores_criacao_coletiva_recomendacao_1",
                    "ai_fatores_criacao_coletiva_recomendacao_2",
                    "ai_fatores_autoconsciencia_prol_coletivo_1",
                    "ai_fatores_alinhamento_para_resultados",
                    "ai_compartilhamento_de_conhecimento",
                    "ai_fatores_autoconsciencia_prol_coletivo_2"
                ]
            }]
        };

    try {
        const jsonData: {[key: string]: any} = await FetchDataBlock(requestData);
        
        // Create an object with each pivot value as a key and an empty array as the default value
        const outputData: {[key: string]: any} = {};
        requestData.pivots[0].pivot_values.forEach((pivot_value: string) => {
            outputData[pivot_value] = jsonData[pivot_value] || [];
        });

        console.log(outputData);

        return outputData;
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}
