import React from "react";
// components
import  SurveyCreatorWidget  from '../components/Surveys/SurveyCreator.jsx';
import { ThreeCircles } from  'react-loader-spinner'
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from '../views/Footer.jsx';
export default function SurveyEditor() {

    const params = useParams();
    const [json, setJson] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async() => {
            try {

                const response = await fetch(`${process.env.REACT_APP_LIME_API_URL}/survey/${params.id_survey}`);
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                let actualData = await response.json();
                setJson((actualData));
                //  setError(null);
            } catch (err) {
                // setError(err);
                console.log(`err ----> ${err}`)
                //setData(null)
            } finally {

                    setLoading(false);

            };


        }
        getData().catch(console.error);
    }

    , []);



    return (
      <>
        <div className={""+(loading?" hidden":" ")}>
          <SurveyCreatorWidget json={json}  />
          <Footer />
        </div>
        <div className={""+ (!loading ? " hidden ":"centralizar-vertical-horizontal")}>
            <ThreeCircles color="#F5D728" height={80} width={80} />
        </div>
      </>
    );
  }
