import Chart from 'react-apexcharts';

const Timeline = ({ end_date, index, ai_demographic_summary }) => {
    const options = {
        chart: {
            height: '50%',
            type: 'scatter',
            toolbar: {
                show: true
            },
            foreColor: "#333",
        },
        title: {
            text: 'Preenchimentos do Grupo',
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '16px',
              fontWeight:  'bold',
              fontFamily:  'Open Sans',
              color:  '#263238'
            },
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            min: 0,
            max: 10,
            tickAmount: 10,
            decimalsInFloat: 0,
            title: {
                text: 'Resultado Individual Source Skills',
                style: {
                    fontSize:  '16px',
                    fontWeight:  'bold',
                    fontFamily:  'Open Sans',
                    color:  '#263238'
                  },
            },
            labels: {
                formatter: (value) => {
                    return value.toFixed(0);
                }
            }
        },
        tooltip: {
            x: {
                format: 'dd/MM/yyyy HH:mm'
            },
            y: {
                title: {
                    formatter: () => 'Indice Source Skills'
                },
                formatter: (value) => {
                    return value.toFixed(2);
                }
            },
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                if (!series) {
                    console.error('Series is undefined.');
                    return 'Error: Series is undefined.';
                }
            
                if (seriesIndex >= series.length) {
                    console.error(`Series index ${seriesIndex} is out of range. Series length: ${series.length}`);
                    return `Error: Series index ${seriesIndex} is out of range. Series length: ${series.length}`;
                }
            
                if (!series[seriesIndex]) {
                    console.error(`Series at index ${seriesIndex} is undefined.`);
                    return `Error: Series at index ${seriesIndex} is undefined.`;
                }
            
                if (dataPointIndex >= series[seriesIndex].length) {
                    console.error(`Data point index ${dataPointIndex} is out of range. Series[${seriesIndex}] length: ${series[seriesIndex].length}`);
                    return `Error: Data point index ${dataPointIndex} is out of range. Series[${seriesIndex}] length: ${series[seriesIndex].length}`;
                }
        
                const seriesData = series[seriesIndex][dataPointIndex];
                const infoData = ai_demographic_summary && dataPointIndex < ai_demographic_summary.length ? ai_demographic_summary[dataPointIndex] : 'N/A';
                return `
                <div class="
                p-2
                border border-gray-400
                bg-gray-200
                max-w-xs sm:max-w-sm    /* responsive max width */
                overflow-auto /* allow text to wrap onto next line */
                whitespace-normal /* allow long words to break onto the next line */
                ">
                <strong class="font-bold text-gray-700">Índice Source Skills:</strong> ${seriesData ? seriesData.toFixed(2) : 'N/A'}
                <br/>
                <strong class="font-bold text-gray-700">AI - Resumo Demográfico:</strong> ${infoData}
                </div>
                `;
            }
            
            
        },
        markers: {
            size: 10, // 100% bigger than the default size of 5
            colors: ['#ffc100'],
        }
    };

    const series = [{
        name: "Indice Source Skills",
        data: end_date.map((date, i) => [date.getTime(), index[i]])
    }];

    return <Chart options={options} series={series} type="scatter"/>;
};

export default Timeline;
