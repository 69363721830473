export async function FetchDataBlock(requestData) {
  try {
      const response = await fetch(
          'https://j5w5ag7110.execute-api.us-east-1.amazonaws.com/lime-pi-fetch-data-block', 
          {
              method: 'POST',
              headers: {
                  'Accept': 'application/form-data',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData),
          }
      );

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonData = await response.json();
      //console.log(jsonData)
      return jsonData;
  } catch (error) {
      console.error("An error occurred while fetching the data.", error);
  }
}