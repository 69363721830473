import { FetchDataBlock } from './DataBlock';

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    dimensions: string[];
    filters: {question_name: string[]}[];
}

interface ResponseData {
    original_value: string[];
}
interface ResponseName{
    name:string
}

export default async function FetchToken(context:string,context_filter_value: string): Promise<ResponseName | null> {
    var context_filter_key = "guid_answer";
    
    if(context == "token")
        context_filter_key = "token";
    //console.log(context,context_filter_value);

    var requestData: RequestData = {
        context: context,
        return_type: "column",
        dimensions: ["original_value"],
        filters: [{question_name: ["token"]}],
    };
    if(context == "token")
        requestData.token = context_filter_value;
    if(context == "answer")
        requestData.guid_answer = context_filter_value;

    try {
        const jsonData: ResponseData = await FetchDataBlock(requestData);
        //console.log(jsonData);
        const response  =jsonData.original_value.length > 0 ? jsonData.original_value[0] : "..."
        return {name:response};
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}
