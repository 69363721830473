import { json } from 'react-router-dom';
import { FetchDataBlock } from './DataBlock';

interface RequestData {
    context: string;
    guid_answer?: string;
    token?: string;
    return_type: string;
    dimensions: string[];
    filters: {question_name: string[]}[];
}

interface ResponseData {
    original_value:[]
}

export default async function FetchAiDimensionRecommendation(guid_answer:string): Promise<ResponseData | null| undefined> {
    var requestData: RequestData = {
        "context":"answer",
        "guid_answer":guid_answer,
        "return_type":"column",
        "dimensions":["original_value"],
        "filters":[{
            "question_name":["ai_dimensoes_recomendacao"]
        }],
    };

    try {
        const jsonData: ResponseData = await FetchDataBlock(requestData);
        //console.log(jsonData);
        if (jsonData.original_value.length > 0){
            return jsonData.original_value[0];
        }
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching the data.", error);
        return null;
    }
}
