import React from 'react';
import logo from '../layouts/midia/img/collabhappiness_logo.png';
import logoCollabSoul from '../layouts/midia/img/logo_collabsoul_colorido.png';

const Main = () => {
    return (
        <div className="min-h-screen bg-gradient-to-r from-green-400 to-blue-500 flex items-center justify-center">
            <div className="bg-white p-10 rounded-lg shadow-md w-full max-w-2xl">
                <div className="flex justify-center items-center mb-8">
                    <img src={logo} alt="Collab Happiness Logo" className="w-1/2 h-auto" />
                </div>
                <h1 className="text-3xl font-bold text-center mb-4">
                    Uma nova forma de pensar sobre felicidade e colaboração no trabalho.
                </h1>
                <p className="text-center mb-4 text-xl font-semibold text-d58545">
                    Somos uma organização em ecossistema dedicada a integrar a inovação, colaboração e felicidade. Propomos e implementamos soluções customizadas de desenvolvimento organizacional e humano, para empresas e seus líderes em torno de novas formas de geração de valor.
                </p>
                <p className="text-center mb-8">
                    Colaboração e felicidade são fundamentais para o sucesso nos negócios e para a implementação eficaz das práticas de ESG (Ambiental, Social e Governança). Ambientes de trabalho colaborativos e felizes aumentam a produtividade, melhoram a retenção de talentos e impulsionam a inovação, ao mesmo tempo em que contribuem para uma cultura organizacional sustentável e ética.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center mb-8">
                    <div className="bg-d58545 p-4 rounded border border-black">
                        <h2 className="text-xl font-bold mb-2">13% de aumento da produtividade</h2>
                        <p>com equipes e trabalhadores felizes. A pesquisa encontrou uma ligação conclusiva entre felicidade e produtividade. Fonte: Universidade de Oxford.</p>
                    </div>
                    <div className="bg-4dd545 p-4 rounded border border-black">
                        <h2 className="text-xl font-bold mb-2">34% dos trabalhadores americanos</h2>
                        <p>se consideram 100% engajados quando felizes em seus trabalhos. Fonte: Instituto Gallup.</p>
                    </div>
                    <div className="bg-4595d5 p-4 rounded border border-black">
                        <div className="bg-4dd545 p-2 rounded">
                            <a href="https://collabsoul.com/collabhappiness" className="text-2xl font-bold mb-2 block text-black">Saiba mais</a>
                        </div>
                        <p>Explore mais sobre a relação entre felicidade e sucesso no trabalho, e como podemos ajudá-lo a alcançar isso.</p>
                    </div>

                </div>
                <div className="flex justify-center">
                    <img src={logoCollabSoul} alt="CollabSoul Logo" className="w-1/3 h-auto" />
                </div>
            </div>
        </div>
    );
};

export default Main;
