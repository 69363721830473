import React, { useState, useEffect, useRef } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import FetchAiFatoresRecommendation from '../../api/FetchAiFatoresRecommendation.tsx';

function FatoresRecommendationLoader({ guid_answer }) {
  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState({});
  const retryCount = useRef(0); // This ref will keep track of the number of retries

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log('Fetching data...');
      const fetchedData = await FetchAiFatoresRecommendation(guid_answer);
      console.log(fetchedData);
      if (fetchedData) {
        // Check if all the keys in the fetchedData object have a non-empty array
        const allDataFetched = Object.values(fetchedData).every(value => Array.isArray(value) && value.length > 0 && value[0]);
        setRecommendations(fetchedData);
        if (allDataFetched) {
          console.log('Data fetched successfully');
          setLoading(false); // Stop loading if all data has been fetched successfully
        } else {
          console.log('Data fetch unsuccessful, retrying...');
          if (retryCount.current < 60) { // Retry for up to 5 minutes (60 retries * 5 seconds each = 300 seconds = 5 minutes)
            retryCount.current++;
            setTimeout(fetchData, 5000); // Retry after 5 seconds
          } else {
            console.log('All retries exhausted, stopping...');
            setLoading(false); // Stop loading if all retries have been exhausted
          }
        }
      }
    };

    if (guid_answer && guid_answer.trim().length > 0) {
      fetchData();
    }

    // Clean up function to reset retryCount on unmount
    return () => {
      retryCount.current = 0;
    };
  }, [guid_answer]);

  const recommendationKeys = [
    "ai_fatores_criacao_coletiva_recomendacao_1",
    "ai_fatores_criacao_coletiva_recomendacao_2",
    "ai_fatores_autoconsciencia_prol_coletivo_1",
    "ai_fatores_alinhamento_para_resultados",
    "ai_compartilhamento_de_conhecimento"
  ];

  return (
    <div>
      {recommendationKeys.map((key, index) => {
        const recommendation = recommendations[key];
        const loadingText = {
          "ai_fatores_criacao_coletiva_recomendacao_1": "Carregando recomendações por IA (Criação Coletiva - Part 1). Por favor, aguarde...",
          "ai_fatores_criacao_coletiva_recomendacao_2": "Carregando recomendações por IA (Criação Coletiva - Part 2). Por favor, aguarde...",
          "ai_fatores_autoconsciencia_prol_coletivo_1": "Carregando recomendações por IA (Autoconsciência em prol do Coletivo). Por favor, aguarde...",
          "ai_fatores_alinhamento_para_resultados": "Carregando recomendações por IA (Alinhamento para Resultados). Por favor, aguarde...",
          "ai_compartilhamento_de_conhecimento": "Carregando recomendações por IA (Compartilhamento de Conhecimento). Por favor, aguarde...",
        }[key];
        if (recommendation && recommendation.length > 0 && recommendation[0]) {
          // If the recommendation array for this key is not empty, display the text
          return <div key={index} dangerouslySetInnerHTML={{ __html: recommendation[0] }}></div>
        } else {
          // If the recommendation array for this key is empty, display a loading spinner and loading text
          return (
            <div style={{ display: "flex", alignItems: "center" }} key={index}>
              <ThreeCircles color="#F5D728" height={80} width={80}  />
              <span style={{ marginLeft: "10px" }}>{loadingText}</span>
            </div>
          );
        }
      })}

    </div>
  );
  
}

export default FatoresRecommendationLoader;
