import React from 'react';
import '../layouts/source_skills_results/normalize.css';
import '../layouts/source_skills_results/webflow.css';
import '../layouts/source_skills_results/test-01-4072d8.webflow.css';
import arrow from '../layouts/source_skills_results/images/arrow.svg';
import sourceSkillsLogo from '../layouts/source_skills_results/images/source-skills.svg';
import collabSoulLogo from '../layouts/source_skills_results/images/collabsoul.svg';
import brainImage from '../layouts/source_skills_results/images/brain.svg';
import signatureImage from '../layouts/source_skills_results/images/assinatura.png';
import FetchName from '../api/FetchName.tsx';

import FetchAVGDimensionType from '../api/FetchAVGDimensionType.tsx';
import FetchAVGDimensionTypeWithoutDimensions from '../api/FetchAVGDimensionTypeWithoutDimensions.tsx';
import IndiceGeralGauge from './IndiceGeralGauge';
import DimensaoChart from './DimensaoChart';
import CompetenciasRadar from './CompetenciasRadar';
import { ThreeCircles } from 'react-loader-spinner'
import { useState, useEffect } from 'react';
import DimensoesRecomendacoesLoader from './ReportLoaders/DimensoesRecomendacoesLoader.jsx'; 
import FatoresRecommendationLoader from './ReportLoaders/FatoresRecommendationLoader.jsx'; 

function SourceSkillsResultAi() {
  const [state, setState] = useState({
    nome: '...',
    dimensoes_nome: [],
    dimensoes_valor: [],
    competencias_nome: [],
    competencias_valor: [],
    indice_geral: [],
    loading_indice: true,
    loading_dimensoes: true,
    loading_competencias: true,
    context: null,
    context_filter: null,
    guid_answer: null,
    token: null
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const guid_answer = urlParams.get('guid');
  document.title = 'Lime PI | Source Skills';
  useEffect(() => {

    if (guid_answer) {
      setState(prevState => ({ ...prevState, context: 'answer', context_filter: guid_answer, guid_answer: guid_answer }));
    }
    else if (token) {
      setState(prevState => ({ ...prevState, context: 'token', context_filter: token, token: token }));

    }


    //const guid_answer = "d3a4ab2a-970d-4af7-87bd-5013cfb73c69"; // replace with actual guid_answer
    async function fetchData() {
      console.log(state.context, state.context_filter)
      const name = await FetchName(state.context, state.context_filter);

      if (name) {
        setState(prevState => ({ ...prevState, nome: name.name }));
      }

      const dimensoes = await FetchAVGDimensionType(state.context, state.context_filter, "Dimensão");
      if (dimensoes) {
        setState(prevState => ({
          ...prevState,
          dimensoes_nome: dimensoes.value,
          dimensoes_valor: dimensoes.avg_substitution_value,
          loading_dimensoes: false
        }));

      }

      const competencias = await FetchAVGDimensionType(state.context, state.context_filter, "Competências Colaborativas");
      if (competencias) {
        setState(prevState => ({
          ...prevState,
          competencias_nome: competencias.value,
          competencias_valor: competencias.avg_substitution_value,
          loading_competencias: false
        }));
      }
      const indice_geral = await FetchAVGDimensionTypeWithoutDimensions(state.context, state.context_filter, "Competências Colaborativas");
      if (indice_geral) {

        setState(prevState => ({
          ...prevState,
          indice_geral: indice_geral.avg_substitution_value,
          loading_indice: false
        }));
      }

    }
    if (state.guid_answer || state.token) {
      console.log("fetching data");
      fetchData();
    }
  }, [state.guid_answer, state.token]);
  return (
    
    <div>
      {/* Rest of the JSX code */}
      <div className="section center wf-section">
        <img src={arrow} alt="" className="arrow" />
        <div data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2" style={{ WebkitTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="hero-content" />
        <div className="columns w-row">
          <div className="column-2 w-col w-col-3 w-col-medium-3">
            <img src={sourceSkillsLogo} loading="lazy" alt="" className="image-5" />
            <img src={collabSoulLogo} loading="lazy" alt="" className="image-6" />
          </div>
          <div className="w-col w-col-9 w-col-medium-9">
            <img src={brainImage} loading="lazy" height={562} width={800} alt="" className="hero-image" />
          </div>
        </div>
      </div>
      <div className="w-container">
        <div className="w-row">
          <div className="w-col w-col-12">
            <h2 className="black-text-name"><span id="nome"> {state.nome=='...'? <ThreeCircles color="#F5D728" height={30} width={30} />:state.nome} </span>,</h2>
            <p>Source Skills é um framework <strong>composto por 23 fatores</strong>,
              que representa o potencial da <strong>colaboração com autoconsciência</strong>, na perspectiva da <strong>gestão</strong>, da <strong>liderança</strong> e do <strong>desenvolvimento humano</strong> e <strong>organizacional</strong>.<br /><br />Source Skills é fruto de pesquisa acadêmica em negócios inovadores e gera valor em aplicações diversas, voltadas a mentoria, educação corporativa e gestão de negócios, bem como em consultoria dirigida a diversos setores.<br /><br />Nesse diagnóstico, você encontra um retrato inicial desses 23 fatores, para que você possa refletir e também potencializar a sua própria jornada de liderança e de autodesenvolvimento.<br /><br />Abraços,</p>
            {/* <img src={signatureImage} loading="lazy" alt="" /> */}
            <p className="black-text-name">Marcelo Ferreira de Castilho</p>
            <p>Cofounder CollabSoul</p>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* <div class="section wf-section"> */}
      <div className="w-container">
        <div className="w-row">
          <div className="w-col w-col-6">
            <h2 className="black-text">Resultado Geral</h2>
            <p className="paragraph-3">Esse é o seu indice geral das habilidades Source Skills.</p>
          </div>
          <div className="w-col w-col-6">{/*<img src="../layouts/source_skills_results/images/resultadogeral.png" loading="lazy" alt=""> */}
            <div id="chart_gauge" >
              {
                !state.loading_indice ? <IndiceGeralGauge indice_geral={state.indice_geral} /> : <ThreeCircles color="#F5D728" height={80} width={80} />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="w-container">
        <div className="w-row" id="row-dimensao">
          <div className="w-col w-col-12">
            {/* <img src="../layouts/source_skills_results/images/pexels-lukas-952360.png" width="341" sizes="(max-width: 479px) 96vw, 341px" srcset="images/pexels-lukas-952360-p-500.png 500w, images/pexels-lukas-952360.png 659w" alt="" class="image-2"> */}
            <div id="chart_1" className="chart_dimensao" >
              {
                !state.loading_dimensoes ? <DimensaoChart dimensao_valores={state.dimensoes_valor} dimensao_colunas={state.dimensoes_nome} /> : <ThreeCircles color="#F5D728" height={80} width={80} />
              }
            </div>
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-12">
            <DimensoesRecomendacoesLoader
              context_filter={state.context_filter}
            />
            {/* <p className="paragraph-2">A dimensão “<strong>Autoconsciência em prol do Coletivo</strong>” nos convida a uma efetividade maior com os outros, começando por nós mesmos, em um “lugar” interno que nos habilita a agir com mais lucidez, coerência, autodeterminação e resiliência.</p>
            <p className="paragraph-2">A dimensão “<strong>Alinhando para um Resultado</strong>” nos impulsiona para engajar pessoas em torno de um objetivo para o alcance de um resultado almejado, gerando maior pertencimento e protagonismo.</p>
            <p className="paragraph-2">A dimensão “<strong>Compartilhamento de Conhecimento</strong>” nos conecta com o toque da conveniência a vantagem que podemos obter ao socializar informações, recursos e a expectativa de reciprocidade.</p>
            <p className="paragraph-2">A dimensão “<strong>Criação Coletiva</strong>” nos conecta com a criatividade e inovação, não só na dimensão cognitiva, mas principalmente na força do campo coletivo emergente.</p> */}
          </div>
        </div>
      </div>
      <div className="w-container">
        <div className="w-row">
          <div className="w-col w-col-12">
            <h2 className="black-text">Mapa dos 23 Fatores</h2>
            {/* <img src="../layouts/source_skills_results/images/limes.jpg" sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" srcset="images/limes-p-1080.jpeg 1080w, images/limes.jpg 1100w" alt="" class="image-8"> */}
            <div id="chart_23competencias" className="chart_23competencias" >
              {!state.loading_competencias ? <CompetenciasRadar dimensao_valores={state.competencias_valor} dimensao_colunas={state.competencias_nome} /> : <ThreeCircles color="#F5D728" height={80} width={80} />}
            </div>
          </div>
        </div>
      </div>
      <div className="w-container">
        <div className="w-row">
          <div className="w-col w-col-12">
            <h2 className="black-text">Os 23 Fatores</h2>
            <FatoresRecommendationLoader
              guid_answer={state.context_filter}
            />
          </div>
        </div>
      </div>
      
      {/* </div> */}
      <div className="footer wf-section">
        <div className="wrap" />
      </div>
      {/* [if lte IE 9]><![endif] */}
    </div>
  );
}


export default SourceSkillsResultAi;