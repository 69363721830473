import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";




import React from "react";
const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showEmbededSurveyTab: true,
  showTranslationTab: true,
  currentLocale:"pt-br"
};
function SaveSurveyJson(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
  },
    body: JSON.stringify(data,null)
};
console.log(requestOptions);
fetch(`${process.env.REACT_APP_LIME_API_URL}/survey`, requestOptions)
    .then(response => response.json())
    .then(json => console.log('success : ' + json))
    .catch(error => console.log('failed to save : ' + error.message));
}


export default function SurveyCreatorWidget(json) {


  if(json==null || json.json == null)
  {
    return null;
  }
  var model = json.json;

  // Survey.Serializer.getProperty("text", "name").readOnly = true;
  var creator = new SurveyCreator(creatorOptions);

  console.log(model.survey_json);
  creator.JSON = model.survey_json;
  //window.localStorage.getItem("survey-json") ||
  creator.saveSurveyFunc = (saveNo, callback) => {
    //window.localStorage.setItem("survey-json", creator.text);
    var result = {
      "id_survey": model.id_survey,
      "survey_json": creator.text
    }

    SaveSurveyJson(result);
  };
  return (
    <div className="survey_creator">
        <SurveyCreatorComponent creator={creator} />
    </div>
  )
}

// function saveSurveyJson(url, json, saveNo, callback) {
//   const request = new XMLHttpRequest();
//   request.open('POST', url);
//   request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
//   request.addEventListener('load', () => {
//       callback(saveNo, true);
//   });
//   request.addEventListener('error', () => {
//       callback(saveNo, false);
//   });
//   request.send(JSON.stringify(json));
// }