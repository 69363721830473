
import React from 'react';
import Chart from 'react-apexcharts';

const CompetenciasRadar = ({ dimensao_valores, dimensao_colunas }) => {

    var options = {
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 500,
                        width: '600px',
                        type: 'bar',
                        toolbar: {
                            show: false,
                            tools: {
                                download: false
                            }
                        }
                        
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '70%',
                            formatter: (value) => {
                                return value.toFixed(2);  // two decimal places in tooltip
                            },
                            // columnWidth: '50%'
                            
                        }
                    },
                    // legend: {
                    // position: "bottom",
                    // fontSize: '14px',
                    // fontFamily: 'Helvetica, Arial',
                    // fontWeight: 400
                    // }
                    xaxis: {
                        labels: {
                            show: true,
                            align: 'right',
                            minWidth: 0,

                            // maxWidth: 160,
                            style: {
                                colors: [],
                                fontSize: '10px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 200,
                                cssClass: 'apexcharts-yaxis-label',

                            },
                        }
                    }
                }
            }
        ],
        series: [{
            name: 'Valor',
            data: dimensao_valores,
        }],
        chart: {
            height: '100%',
            width: '100%',
            type: 'radar',
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
        },
        grid: {
            padding: {
                top: 0, // top padding
                right: 0, // right padding
                bottom: 0, // bottom padding
                left: 0 // left padding
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => {
                return value.toFixed(2);  // two decimal places in tooltip
            },
        },
        plotOptions: {
            radar: {
                //size: 350,
                polygons: {
                    strokeColors: '#e9e9e9',
                    fill: {
                        colors: ['#f8f8f8', '#fff']
                    }
                }
            }
        },
        //title: {
        //    text: 'Mapa das 23 Competências'
        //},
        colors: ['#A38129'],
        markers: {
            size: 5,
            colors: ['#fff'],
            strokeColor: '#A38129',
            strokeWidth: 2,
            hover: {
                size: 10
            }
        },
        tooltip: {
            y: {
                formatter: (value) => {
                    return value.toFixed(2);  // two decimal places in tooltip
                },
            }
        },
        xaxis: {
            categories:
                dimensao_colunas
        },
        yaxis: {
            tickAmount: 7,
        }
    };
    // Define the styles object for the chart container
    const styles = {
        chartContainer: {
            minWidth: '300px',
            height: '100%',
            // margin: "-60px",
            // overflow: "hidden"
        }
    };
    return (
        <div style={styles.chartContainer}>
            <Chart options={options} series={options.series} type="radar" />
        </div>
    );
};

export default CompetenciasRadar;