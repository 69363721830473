import React from "react";
import RandomAiBanner from "../components/RandomAiBanner.jsx";
// components

// import CardStats from "components/Cards/CardStats.js";

export default function Header() {
  return (
    <>
        <div className="ch-demo-header ch-demo-header-image  lime-bg-light">
            <a target="_blank" rel="noopener noreferrer" href="https://www.collabsoul.com">
                {/* <img src={require("../layouts/midia/img/limelogohorizontal.png")} alt="Lime Fact." height="17" width="317" /> */}
                <RandomAiBanner />
            </a>
        </div>

    </>
  );
}
